import React from 'react';
import axios from 'axios';
import url, { zcommerceUrl } from '../config';
import moment from 'moment';
import swal from '@sweetalert/with-react'
import { FETCH_BITRIXID_START, FETCH_BITRIXID_SUCCESS, FETCH_COMMENTS_START, FETCH_COMMENTS_SUCCESS, POST_COMMENTS_START, POST_COMMENTS_SUCCESS, FETCH_ZUNSOLAR_DEALER_INVENTORY_SUCCESS, FETCH_ZUNPULSE_CUSTOMER_QUOTATION, FETCH_ZUNPULSE_SELLING_PRICE, FETCH_ZUNPULSE_CUSTOMER_QUOTATION_PRODUCTS, FETCH_DEALER_VISIT_DATA, FETCH_DISTRIBUTOR_KYC_DATA, FETCH_RETAILERS_COUNT_FOR_DISTRIBUTOR, FETCH_DISTRIBUTOR_LEDGER_DATA, MT_LEAD_DATA } from './actiontypes';

export function fetchBitrixId(bitrixId) {
    return (dispatch) => {

        dispatch({ type: FETCH_BITRIXID_START, payload: [] });
        fetchBitrixData(bitrixId).then(data => {
            dispatch({ type: FETCH_BITRIXID_SUCCESS, payload: data });
        })
    }
}

export function addComments(newCommentData, view = "solar") {

    return () => {
        let route;
        switch (view) {
            case 'solar':
                route = `lead/comment/save`;
                break;
            case 'zunpulse':
                route = `zunpulse/lead/comment/save`;
                break;
            case 'zunsolar':
                route = `zunsolar/lead/comment/save`;
                break;
            default:
                route = `lead/comment/save`
                break;
        }
        let commentData = new URLSearchParams();
        Object.keys(newCommentData ? newCommentData : {}).map(key => {
            commentData.append(key, newCommentData[key]);
        });
        let postBody = axios.post(url.pathBackend + route, commentData).then((res) => { console.log("responseeeee ", res) }).then((res) => {
        }).catch((error) => {
            console.log(error)
        })
    }
}

export function editLead(newData, oldData, bitrixId) {

    let dateFormats = {
        SITE_ASSESSMENT_DATE: 'YYYYMMDD',
        ADDED_ON: 'YYYYMMDD',
        NEXT_ACTION_DATE: 'YYYYMMDD',
        SALES_DATE: 'YYYYMMDD',
        saScheduleDate: 'YYYYMMDD'
    }
    return () => {
        let modifiedObj = { BITRIX_ID: bitrixId }
        let modifiedFields = [];
        Object.keys(oldData).forEach((e) => {

            if (oldData[e] != newData[e]) {
                if (Object.keys(dateFormats).includes(e) && newData[e]) {
                    newData[e] = moment(newData[e]).format(dateFormats[e])
                }
                modifiedObj[e] = newData[e]
                modifiedFields.push({
                    field: e,
                    from: oldData[e],
                    to: newData[e]
                })
            }
            return modifiedObj;
        })
        if (modifiedObj.SITE_ASSESSMENT_DATE) {
            modifiedObj.SA_CONFIRMATION_STATUS = 'STC';
            modifiedObj.SA_COUNT = parseInt(newData.SA_COUNT) + 1
            console.log(oldData.saScheduleDate, newData.saScheduleDate)
            if(!oldData.saScheduleDate && !newData.saScheduleDate) {
                modifiedObj.saScheduleDate = moment().format("YYYYMMDD");
            }
        }
        let formData = {
            lead: modifiedObj
        }
        let historyObj = {
            bitrixId: bitrixId,
            updatedBy: 'Shubham',
            updates: modifiedFields
        }
        let postBody = axios.post(url.pathBackend + "lead/edit/" + bitrixId, formData).then((res) => {
            if (res.data.error) {
                swal("some error occured")
            }
        }).catch((error) => {
            swal("some error occured")
        });
        // axios.post(url.pathBackend + "lead/saveLeadHistory",historyObj).then((res)=>{
        //     console.log("history obj",res)
        // });
    }
}

async function fetchBitrixData(bitrixId) {
    try {

        // let formData = new URLSearchParams();
        // formData.append("bitrixId",bitrixId);

        let leadDetails = axios.post(url.pathBackend + "lead/details/" + bitrixId);
        let comments = axios.post(url.pathBackend + "lead/comments/" + bitrixId);
        let history = axios.post(url.pathBackend + "lead/history/" + bitrixId);
        let allData = await Promise.all([leadDetails, comments, history]);

        //  let response = await Promise.all([allData[0].json(), allData[1].json()]);
        // console.log("ALl",response);


        let leadData = { ...allData[0].data }
        leadData.data['comments'] = allData[1].data;
        leadData.data['history'] = allData[2].data;
        // let commentsData = allData[1].json() || [];
        // leadData.comments = commentsData;
        return leadData;

    }
    catch (e) {
        console.log(e);
        return {};
    }
}

export function fetchZunpulseLead(leadId) {
    return (dispatch) => {


        dispatch({ type: FETCH_BITRIXID_START, payload: [] });
        //  fetch("http://localhost:3001/lead/details/"+ bitrixId).then(res=>res.json()).then(data=>{
        //      console.log("shivam",data)
        //     dispatch({type:FETCH_BITRIXID_SUCCESS,payload:data.data});
        //  })
        // console.log('leadInfo',leadInfo); 
        fetchZunpulseLeadDetails(leadId).then(data => {
            dispatch({ type: FETCH_BITRIXID_SUCCESS, payload: data });
        })
    }
}

async function fetchZunpulseLeadDetails(pulseId) {
    try {

        let leadData = axios.post(url.pathBackend + "zunpulse/lead/details/" + pulseId);
        let comments = axios.post(url.pathBackend + "zunpulse/lead/comments/" + pulseId);
        let history = axios.post(url.pathBackend + "zunpulse/lead/history/" + pulseId);
        let allData = await Promise.all([leadData, comments, history]);
        // console.log("alldataatatata",allData)

        let leadInfo = { ...allData[0].data }
        leadInfo.data['comments'] = allData[1].data;
        leadInfo.data['history'] = allData[2].data
        // console.log("leadInfo",leadInfo)
        return leadInfo;
    } catch (e) {
        console.log(e)
        return {}
    }
}

export function fetchZunsolarLead(id) {
    return async (dispatch) => {
        try {
            let leadData = axios.post(url.pathBackend + "zunsolar/lead/details/" + id);
            let comments = axios.post(url.pathBackend + "zunsolar/lead/comments/" + id);
            let history = axios.post(url.pathBackend + "zunsolar/lead/history/" + id);
            let allData = await Promise.all([leadData, comments, history]);
            let leadInfo = { ...allData[0].data }
            leadInfo.data['comments'] = allData[1].data;
            leadInfo.data['history'] = allData[2].data;
            dispatch({ type: FETCH_BITRIXID_SUCCESS, payload: leadInfo });
        }
        catch (e) {
            console.log(e);
        }
    }
}

const fetchSingleMtInfo=async(id)=>{
    try{
        let responseLeadData= await axios.post(url.pathBackend + "zunsolar/lead/details/" + id);
        
        if(responseLeadData.data && responseLeadData.data.data){
           const data=responseLeadData?.data.data.leadType
           return data
        }
    }
    catch(e){
    console.log("error",e)
    }
}

export function editZunpulseLead(newData, oldData, leadId) {
    let dateFormats = {
        nextActionDate: 'YYYYMMDD',
        ADDED_ON: 'YYYYMMDD'
    }
    return () => {
        let modifiedObj = { leadId: leadId };
        let modifiedFields = []
        Object.keys(oldData).map((e) => {
            if (oldData[e] != newData[e]) {
                if (Object.keys(dateFormats).includes(e) && newData[e]) {
                    newData[e] = moment(newData[e]).format(dateFormats[e]);
                }
                modifiedObj[e] = newData[e];
                modifiedFields.push({
                    field: e,
                    from: oldData[e],
                    to: newData[e]
                })
            }
            return modifiedObj;
        })

        let formData = {
            lead: modifiedObj
        }

        let postBody = axios.post(url.pathBackend + "zunpulse/lead/edit/" + leadId, formData).then((res) => {
            if (res.data.error) {
                swal("some error occured")
            }
            // if(res.data.data){
            //     swal("lead saved","success")
            // }
            // if(res)
        }).catch((error) => {
            console.log(error)
            swal("some error occured");
        });

    }

}

export function addZUnpulseComments() {

}


export function editZunsolarLead(newData, oldData, leadId,mtView=false) {
    let dateFormats = {
        nextActionDate: 'YYYYMMDD',
        ADDED_ON: 'YYYYMMDD',
        partner_onboarding_date: 'YYYYMMDD'
    }

    

    return async(dispatch) => {
        let combineData = {
            ...oldData,
            ...newData
        };

        dispatch({type:MT_LEAD_DATA,payload:combineData})
        let modifiedObj = { solarId: leadId };
        let modifiedFields = []
        if(newData['stage'] == 'Sold' && oldData['stage'] != newData['stage']){
            newData['responsible_person'] = ''
        }
        Object.keys(oldData).map((e) => {
            if (oldData[e] != newData[e]) {
                if (Object.keys(dateFormats).includes(e) && newData[e]) {
                    newData[e] = moment(newData[e]).format(dateFormats[e]);
                }
                modifiedObj[e] = newData[e];
                modifiedFields.push({
                    field: e,
                    from: oldData[e],
                    to: newData[e]
                })
            }
            return modifiedObj;
        })

        let formData = {
            lead: modifiedObj
        }

    const responseLeadData=await fetchSingleMtInfo(leadId)
    console.log('responseLeadData',responseLeadData);
        let postBody = axios.post(url.pathBackend + "zunsolar/lead/edit/" + leadId, formData).then((res) => {
            if (res.data.error) {
                swal("some error occured")
                
            }
            console.log('check MT', mtView, responseLeadData);

            if(mtView){
                
                let distributors = ["Distributor","Distributor ZP","Distributor ZV"]
                if(distributors.includes(responseLeadData)){
                    window.open(window.location.origin+"/mt/distributorLeadDetails/"+leadId,"_self")
                    return
                }

                else{
                window.open(window.location.origin+"/mt/dealerLeadDetails/"+leadId,"_self")
                }
            }
            // if(res.data.data){
            //     swal("lead saved","success")
            // }
            // if(res)
        }).catch((error) => {
            console.log(error)
            swal("some error occured");
        });

    }
}
export function editMTLeadProfile(newData, oldData, leadId) {
    let dateFormats = {
        nextActionDate: 'YYYYMMDD',
        ADDED_ON: 'YYYYMMDD',
        partner_onboarding_date: 'YYYYMMDD'
    }

    

    return async(dispatch) => {
        let combineData = {
            ...oldData,
            ...newData
        };
        console.log('newData api' ,newData);
        console.log('oldData api',oldData);

        dispatch({type:MT_LEAD_DATA,payload:combineData})
        let modifiedObj = { solarId: leadId };
        let modifiedFields = []
        if(newData['stage'] == 'Sold' && oldData['stage'] != newData['stage']){
            newData['responsible_person'] = ''
        }
        Object.keys(oldData).map((e) => {
            if (oldData[e] != newData[e]) {
                if (Object.keys(dateFormats).includes(e) && newData[e]) {
                    newData[e] = moment(newData[e]).format(dateFormats[e]);
                }
                console.log('newData[e];',e,newData[e]);
                modifiedObj[e] = newData[e];
                modifiedFields.push({
                    field: e,
                    from: oldData[e],
                    to: newData[e]
                })
            }
            return modifiedObj;
        })
        let apiData = {
            lead:modifiedObj,
            profilePic: modifiedObj.profilePic || ''
        }
        let apiFormData = new FormData();
        console.log('modifiedObj',modifiedObj);
        for(let key in modifiedObj){
            apiFormData.append(key, modifiedObj[key]);
        }


    // const responseLeadData=await fetchSingleMtInfo(leadId)
    // console.log('responseLeadData',responseLeadData);
        let postBody = axios.post(url.pathBackend + "zunsolar/lead/editMTView/" + leadId, apiFormData).then((res) => {
            if (res.data.error) {
                swal("some error occured")
                return
                
            }
        window.open(window.location.origin + `/mt/createUpdateFinal/${leadId}`,"_self")

            
        }).catch((error) => {
            console.log(error)
            swal("some error occured");
        });

    }
}
export function fetchZunsolarDealerInventory(solarId) {
    return async (dispatch) => {
        try {
            let res = await axios.post(`${url.pathBackend}zunsolar/lead/inventory/fetch`, {
                solarId
            }) || {};
            res = res.data || {};
            let inventoryData = res.data || [];
            dispatch({ type: FETCH_ZUNSOLAR_DEALER_INVENTORY_SUCCESS, payload: inventoryData });
        }
        catch(e) {
            console.log("fetchZunsolarDealerInventory Error", e);
            return;
        }
    }
}

export function editZunsolarDealerInventory(id, data = {}, allData = []) {
    return async (dispatch) => {
        try {
            if(!Object.keys(data).length) return;
            let res = await axios.post(`${url.pathBackend}zunsolar/lead/inventory/edit`, {
                id: id,
                data: data
            }) || {};
            res = res.data || {};
            if(!res.success) {
                alert("Some error occured");
                return;
            }
            allData = JSON.parse(JSON.stringify(allData));
            let index = allData.findIndex(row => row.id == id);
            allData[index] = { ...allData[index], ...data };
            dispatch({ type: FETCH_ZUNSOLAR_DEALER_INVENTORY_SUCCESS, payload: allData });
        }
        catch(e) {
            console.log("fetchZunsolarDealerInventory Error", e);
            return;
        }
    }
}

export function addZunsolarDealerInventory(data = {}) {
    return async (dispatch) => {
        try {
            if(!Object.keys(data).length) return;
            let res = await axios.post(`${url.pathBackend}zunsolar/lead/inventory/add`, {
                data: data
            }) || {};
            res = res.data || {};
            if(!res.success) {
                alert("Some error occured");
                return;
            }
            dispatch({ type: FETCH_ZUNSOLAR_DEALER_INVENTORY_SUCCESS, payload: res.data || [] });
        }
        catch(e) {
            console.log("fetchZunsolarDealerInventory Error", e);
            return;
        }
    }
}
export function addZunpulseCustomerQuotation(apiData = {}) {
    return async (dispatch) => {
        try {
            console.log('apiHit', apiData);
            if(!Object.keys(apiData).length) return;
            let response = await axios.post(`${url.pathBackend}zunpulse/lead/addQuotation`, apiData) || {};
            response = response.data || {};
            console.log('response', response);
            if(!response.success) {
                alert("Some error occured");
                return;
            }
            alert("Added Successfully")
            dispatch({ type: FETCH_ZUNPULSE_CUSTOMER_QUOTATION, payload: response.data|| [] });
        }
        catch(e) {
            console.log("addZunpulseCustomerQuotation Error", e);
            return;
        }
    }
}
export function getZunpulseCustomerQuotation(leadId){
    return async(dispatch)=>{
        try {
            console.log('leadId',leadId);
            if(!leadId){
                return;
            }
            let response = await axios.post(`${url.pathBackend}zunpulse/lead/getQuotation`, {leadId:leadId}) || {};
            response = response.data || {};
            console.log('get response', response);
            if(!response.success) {
                alert("Some error occured");
                return;
            }
            dispatch({ type: FETCH_ZUNPULSE_CUSTOMER_QUOTATION, payload: response.data|| [] });

            
        } catch (error) {
            console.log("getZunpulseCustomerQuotation Error", error);
            alert("Some error occured");
            return;
        }
    }
    
}

export function getZunpulseQuotationProducts(quotationId){
    return async(dispatch)=>{
        try {
            if(!quotationId){
                return;
            }
            let response = await axios.post(`${url.pathBackend}zunpulse/lead/getQuotationProduct`, {quotationId:quotationId}) || {};
            response = response.data || {};
            if(!response.success) {
                alert("Some error occured");
                return;
            }
            dispatch({ type: FETCH_ZUNPULSE_CUSTOMER_QUOTATION_PRODUCTS, payload: response.data|| [] });

            
        } catch (error) {
            console.log("getZunpulseQuotationProducts Error", error);
            alert("Some error occured");
            return;
        }
    }
    
}

export function getSellingPriceMapping(){
    return async(dispatch)=>{
        try {
            
            let  response = await axios.get(`${zcommerceUrl.productPathBackend}product/getSellingPriceMapping`);
            response = response.data;
            const data = response && response.data || {};
            dispatch({type: FETCH_ZUNPULSE_SELLING_PRICE, payload:data || {}})

        } catch (error) {
            
            alert("some Error  Occured");
            return;
        }
    }
}

export function addZunsolarDealerVisitData(apiData) {
    return async (dispatch) => {
        try {
            let res = await axios.post(`${url.pathBackend}zunsolar/lead/addDealerVisit`, apiData);

            res = res.data || {};
            if(res.status=="success"){
                res = res.data;
                dispatch({ type: FETCH_DEALER_VISIT_DATA, payload: res });
            }
        }
        catch(e) {
            console.log("fetchZunsolarDealerInventory Error", e);
            return;
        }
    }
}
export function fetchZunsolarDealerVisitData(solarId) {
    return async (dispatch) => {
        try {
            let res = await axios.post(`${url.pathBackend}zunsolar/lead/getDealerVisit`, {solarId:solarId}) ;
            res = res.data || {};
            if(res && res.status=='success'){
                res = res.data || []
                dispatch({ type: FETCH_DEALER_VISIT_DATA, payload: res });

            }
        }
        catch(e) {
            return;
        }
    }
}

export function addZunsolarDistributorKYC(apiData) {
    return async (dispatch) => {
        try {
            console.log('formData', apiData);
            let res = await axios.post(`${url.pathBackend}zunsolar/lead/addDistributorKYC`, apiData);

            res = res.data || {};
            if(res.status=="success"){
                res = res.data;
                dispatch({ type: FETCH_DISTRIBUTOR_KYC_DATA, payload: res });
            }
        }
        catch(e) {
            console.log("FETCH_DISTRIBUTOR_KYC_DATA Error", e);
            return;
        }
    }
}
export function fetchZunsolarDistributorKYCData(solarId) {
    return async (dispatch) => {
        try {
            let res = await axios.post(`${url.pathBackend}zunsolar/lead/getDistributorKYC`, {solarId:solarId}) ;
            res = res.data || {};
            if(res && res.status=='success'){
                res = res.data || []
                dispatch({ type: FETCH_DISTRIBUTOR_KYC_DATA, payload: res });

            }
        }
        catch(e) {
            return;
        }
    }
}
export const downloadKYCFile = async(fileName)=>{
	try {
		const userData =  JSON.parse(localStorage.getItem('userObj'));
		const email = userData.email || "devteam@zunpulse.com"
		if(!fileName){
			alert("file does not exits");
			return;
		}
		const apiData = {
			email: email,
			fileName: fileName,
			key: "8ALS!Fcm#iWX_IgN"
		}
		console.log('apiData', apiData)
		let invoiceRes = await axios.post(`${url.pathBackend}zunsolar/lead/downloadKYCFile`,apiData)
		invoiceRes = invoiceRes.data;
		if(invoiceRes && invoiceRes.fileData && invoiceRes.fileData.link){
			window.open(invoiceRes.fileData.link,"_blank")
			// window.location.reload(false);
			// alert("Operation Performed successfully");
			return;
		}
		
		alert("File not found");
			return;
		// console.log('warrantyres', warrantyRes );
		
		
	} catch (error) {
		console.log('error', error);
		alert("some error occured");
		return;
		
	}
}

export function fetchRetailersCountForDistributor(distributorId){
    try {
      return async(dispatch)=>{
          let apiData = {
            distributorId: distributorId
          }
      let apiRes = await axios.post(`${url.pathBackend}zunsolar/lead/getRetailersCount`, apiData);
      apiRes = apiRes.data
      if(apiRes.status=="success"){
        console.log('apiRes',apiRes);
          apiRes = apiRes && apiRes.count || []
          dispatch({type:FETCH_RETAILERS_COUNT_FOR_DISTRIBUTOR, payload:  apiRes ||  {}})
          
      }
    
  }
  } catch (error) {
      alert("something went wrong")
  
      
  }
  }
  export function fetchLeadgerDataForDistributor(distributorId){
    try {
      return async(dispatch)=>{
          let apiData = {
            key: "bSFx2wcJ2MUnPVf",
            user: "12345678",
            dealerCode:distributorId
          }
      let apiRes = await axios.post(`https://operations.zunroof.com/api/payment/fetchpaymentbydealer`, apiData);
      apiRes = apiRes.data
      console.log('apiRes', apiRes);
      let data = apiRes && apiRes.data || [];
        dispatch({type:FETCH_DISTRIBUTOR_LEDGER_DATA, payload:  data ||  []})
      
    
  }
  } catch (error) {
      alert("something went wrong")
  
      
  }
  }